import Swiper, {Autoplay, EffectCoverflow, Navigation, Pagination, Thumbs} from 'swiper'
Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay, Thumbs])

export default class SwiperSlider {
    createSlider () {
        return new Swiper('#slider--slider', {
            slidesPerView: 1,
            autoplay: {
                delay: 6500
            },
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--slider-next',
                prevEl: '.slider--slider-prev',
            },
            pagination: {
                el: '.swiper-pagination--slider',
                clickable: true
            },
            breakpoints: {}
        })
    }

    createPrice () {
        return new Swiper('#slider--price', {
            slidesPerView: 1,
            autoplay: {
                delay: 6000
            },
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--slider-next',
                prevEl: '.slider--slider-prev',
            },
            pagination: {
                el: '.swiper-pagination--price',
                clickable: true
            },
            breakpoints: {}
        })
    }

    createObjects () {
        return new Swiper('#slider--objects', {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--slider-next',
                prevEl: '.slider--slider-prev',
            },
            pagination: {
                el: '.swiper-pagination--objects',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    slidesPerGroup: 1
                },
                320: {
                    slidesPerView: 1,
                    slidesPerGroup: 1
                },
                360: {
                    slidesPerView: 1,
                    slidesPerGroup: 1
                },
                375: {
                    slidesPerView: 1,
                    slidesPerGroup: 1
                },
                414: {
                    slidesPerView: 1,
                    slidesPerGroup: 1
                },
                480: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                514: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                640: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                768: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                991: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                },
                1024: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                },
                1240: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                },
                1440: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                }
            }
        })
    }

    createObjectsMore () {
        return new Swiper('#slider--objects-more', {
            slidesPerView: 3,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--objects-more-next',
                prevEl: '.slider--objects-more-prev',
            },
            pagination: {
                el: '.swiper-pagination--objects-more',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1
                },
                320: {
                    slidesPerView: 1
                },
                360: {
                    slidesPerView: 1
                },
                375: {
                    slidesPerView: 1
                },
                414: {
                    slidesPerView: 1
                },
                480: {
                    slidesPerView: 2
                },
                514: {
                    slidesPerView: 2
                },
                640: {
                    slidesPerView: 2
                },
                768: {
                    slidesPerView: 2
                },
                991: {
                    slidesPerView: 3
                },
                1024: {
                    slidesPerView: 3
                },
                1240: {
                    slidesPerView: 3
                },
                1440: {
                    slidesPerView: 3
                }
            }
        })
    }

    createObjectsGallery () {
        let swiper = new Swiper("#slider--objects-gallery-thumbs", {
            loop: false,
            preloadImages: false,
            spaceBetween: 10,
            slidesPerView: 4,
            freeMode: true,
            watchSlidesProgress: true,
            navigation: {
                nextEl: ".swiper-button-thumbs-next",
                prevEl: ".swiper-button-thumbs-prev",
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 5
                },
                320: {
                    slidesPerView: 2,
                    spaceBetween: 5
                },
                360: {
                    slidesPerView: 2,
                    spaceBetween: 5
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 5
                },
                414: {
                    slidesPerView: 3,
                    spaceBetween: 5
                },
                480: {
                    slidesPerView: 3,
                    spaceBetween: 5
                },
                514: {
                    slidesPerView: 3,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 4,
                    spaceBetween: 10
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 10
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 10
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 10
                },
                1240: {
                    slidesPerView: 4,
                    spaceBetween: 10
                },
                1440: {
                    slidesPerView: 4,
                    spaceBetween: 10
                }
            }
        })

        return new Swiper("#slider--objects-gallery", {
            loop: false,
            preloadImages: false,
            spaceBetween: 10,
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            thumbs: {
                swiper: swiper,
            }
        })
    }

    createPartner () {
        return new Swiper('#slider--partner', {
            slidesPerView: 5,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--slider-next',
                prevEl: '.slider--slider-prev',
            },
            pagination: {
                el: '.swiper-pagination--partner',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2
                },
                320: {
                    slidesPerView: 2
                },
                360: {
                    slidesPerView: 2
                },
                375: {
                    slidesPerView: 2
                },
                414: {
                    slidesPerView: 2
                },
                480: {
                    slidesPerView: 2
                },
                514: {
                    slidesPerView: 3
                },
                640: {
                    slidesPerView: 3
                },
                768: {
                    slidesPerView: 4
                },
                991: {
                    slidesPerView: 4
                },
                1024: {
                    slidesPerView: 4
                },
                1240: {
                    slidesPerView: 5
                },
                1440: {
                    slidesPerView: 5
                }
            }
        })
    }

    createReviewImg () {
        return new Swiper('#slider--review-img', {
            slidesPerView: 5,
            slidesPerGroup: 5,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--slider-next',
                prevEl: '.slider--slider-prev',
            },
            pagination: {
                el: '.swiper-pagination--review-img',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                320: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                360: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                375: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                414: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                480: {
                    slidesPerView: 2,
                    slidesPerGroup: 2
                },
                514: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                },
                640: {
                    slidesPerView: 3,
                    slidesPerGroup: 3
                },
                768: {
                    slidesPerView: 4,
                    slidesPerGroup: 4
                },
                991: {
                    slidesPerView: 4,
                    slidesPerGroup: 4
                },
                1024: {
                    slidesPerView: 4,
                    slidesPerGroup: 4
                },
                1240: {
                    slidesPerView: 5,
                    slidesPerGroup: 5
                },
                1440: {
                    slidesPerView: 5,
                    slidesPerGroup: 5
                }
            }
        })
    }

    createVideo () {
        return new Swiper('#slider--video', {
            slidesPerView: 3,
            spaceBetween: 20,
            navigation: {
                nextEl: '.slider--video-next',
                prevEl: '.slider--video-prev',
            },
            pagination: {
                el: '.swiper-pagination--video',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                414: {
                    slidesPerView: 2,
                    spaceBetween: 10
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 15
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 15
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 15
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 15
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 20
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 20
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 20
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 20
                }
            }
        })
    }

    constructor () {
        if(document.getElementById('slider--slider') != null) {
            this.createSlider()
        }

        if(document.getElementById('slider--price') != null) {
            this.createPrice()
        }

        if(document.getElementById('slider--objects') != null) {
            this.createObjects()
        }

        if(document.getElementById('slider--objects-more') != null) {
            this.createObjectsMore()
        }

        if(document.getElementById('slider--objects-gallery')!= null) {
            this.createObjectsGallery()
        }

        if(document.getElementById('slider--partner') != null) {
            this.createPartner()
        }

        if(document.getElementById('slider--review-img') != null) {
            this.createReviewImg()
        }

        if(document.getElementById('slider--video') != null) {
            this.createVideo()
        }
    }
}
