/*eslint-disable no-unused-vars*/

import { createApp } from 'vue'
import Maska from 'maska'
import form from '@/store/form'
import { Fancybox } from '@fancyapps/ui'
import './assets/js/css/ImportCss'
import SwiperSlider from '@/assets/js/Swiper/Swiper'
import Faq from '@/assets/js/Faq/Faq'
import ShowHidden from '@/assets/js/ShowHidden/ShowHidden'
import TableButton from '@/assets/js/Table/TableButton/TableButton'
import VideoImg from '@/assets/js/Video/VideoImg/VideoImg'
import Map from '@/assets/js/Map/Map'
import HeaderMobile from '@/assets/js/Header/Mobile/HeaderMobile'
import MenuMobile from '@/assets/js/Menu/Mobile/MenuMobile'
import MenuMobile2 from '@/assets/js/Menu/Mobile/2/MenuMobile2'
import ButtonTop from '@/assets/js/Button/Top/ButtonTop'
import AppFormPopup from '@/components/Form/Popup/AppFormPopup.vue'
import AppFormPrice from '@/components/Form/Price/AppFormPrice.vue'

const $ = window.$ = window.jQuery = require('jquery')

document.addEventListener('DOMContentLoaded', () => {
    Fancybox.bind('[data-fancybox]', {
        mainClass: 'main__fancybox'
    })
    new SwiperSlider()
    new Faq()
    new ShowHidden()
    new TableButton()
    new VideoImg()
    new Map()
    new HeaderMobile()
    new MenuMobile()
    new MenuMobile2()
    new ButtonTop()

    if(document.getElementById('form--popup')!= null) {
        createApp({})
            .component('form-popup', AppFormPopup)
            .use(Maska)
            .use(form)
            .mount('#form--popup')
    }

    if(document.getElementById('form--price')!= null) {
        createApp({})
            .component('form-price', AppFormPrice)
            .use(Maska)
            .use(form)
            .mount('#form--price')
    }
})
