<template lang="pug">
.form-popup.form-popup-page
  .form-popup__blocks
    .form-popup__block
      .form-popup__fields
        .form-popup__field
          label.input-text.input-text-icon
            span.input-text__label(v-html="formFieldNameLabel")
            input.input-text__input(type="text" v-model="fieldName")
            svg.input-text__icon
              use(xlink:href="#contact-user")
        .form-popup__field
          label.input-text.input-text-icon
            span.input-text__label(v-html="formFieldPhoneLabel")
            input.input-text__input(type="tel" v-model="fieldPhone" v-maska="['+7 (###) ##-##-##', '+7 (###) ###-##-##']")
            svg.input-text__icon
              use(xlink:href="#contact-phone")
        .form-popup__field
          label.input-text.input-text-icon
            span.input-text__label(v-html="formFieldEmailLabel")
            input.input-text__input(type="email" v-model="fieldEmail")
            svg.input-text__icon
              use(xlink:href="#contact-email")

        .form-popup__field
          .form-popup__three
            label.input-text
              span.input-text__label(v-html="formFieldWidthLabel")
              input.input-text__input(type="text" v-model="fieldWidth")
            label.input-text
              span.input-text__label(v-html="formFieldLengthLabel")
              input.input-text__input(type="text" v-model="fieldLength")
            label.input-text
              span.input-text__label(v-html="formFieldHeightLabel")
              input.input-text__input(type="text" v-model="fieldHeight")
        .form-popup__field
          .form-popup__sub(v-html="formFieldTypeLabel")
          .form-popup__checks
            label.input-check
              input.input-check__input(type="checkbox" value="Быстровозводимые здания" v-model="fieldType")
              .input-check__buttons
                .input-check__button
                  svg.input-check__icon
                    use(xlink:href="#icon-check")
                .input-check__value Быстровозводимые здания
            label.input-check
              input.input-check__input(type="checkbox" value="Каркасно-тентовое здание" v-model="fieldType")
              .input-check__buttons
                .input-check__button
                  svg.input-check__icon
                    use(xlink:href="#icon-check")
                .input-check__value Каркасно-тентовое здание
        .form-popup__field
          label.input-text.input-text-icon
            span.input-text__label(v-html="formFieldAddressLabel")
            input.input-text__input(type="text" v-model="fieldAddress")
            svg.input-text__icon
              use(xlink:href="#contact-map")
        .form-popup__field
          label.input-text.input-text-icon
            span.input-text__label(v-html="formFieldTimeLabel")
            input.input-text__input(type="text" v-model="fieldTime")
            svg.input-text__icon
              use(xlink:href="#contact-time")

    .form-popup__block
      .form-popup__img
        img.form-popup__img-wrapper(:src="formImg")

  .form-popup__bottom
    .form-popup__fields
      .form-popup__field
        .consent
          input.consent__input(type="checkbox" id="form-checkbox-1" v-model="consent" value="consent" checked="checked")
          label.consent__label(for="form-checkbox-1" v-html="formConsent")
      .form-popup__field
        .form-popup__button
          .button.button-small(
            @click="getSend()"
            :class="{'button-disable': v$.$invalid}"
          )
            .button__wrapper
              .button__text(v-html="formButtonName")
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import { mapActions } from 'vuex'

export default {
  name: 'AppFormPrice',

  setup () {
    return { v$: useVuelidate() }
  },

  props: [
    "formSettingFormTitle",
    "formSettingFormSubject",

    "formFieldName",
    "formFieldNameLabel",
    "formFieldPhone",
    "formFieldPhoneLabel",
    "formFieldEmail",
    "formFieldEmailLabel",
    "formFieldWidth",
    "formFieldWidthLabel",
    "formFieldLength",
    "formFieldLengthLabel",
    "formFieldHeight",
    "formFieldHeightLabel",
    "formFieldType",
    "formFieldTypeLabel",
    "formFieldAddress",
    "formFieldAddressLabel",
    "formFieldTime",
    "formFieldTimeLabel",

    "formConsent",

    "formButtonName",

    "formPageName",
    "formPageRedirect",

    "formPageId",
    "formPagePagetitle",

    "formImg"
  ],

  data () {
    return {
      formSettingTitle: '',
      formSettingSubject: '',

      consent: ['consent'],

      fieldName: '',
      fieldPhone: '',
      fieldEmail: '',
      fieldWidth: '',
      fieldLength: '',
      fieldHeight: '',
      fieldType: [],
      fieldAddress: '',
      fieldTime: '',

      files: ''
    }
  },

  methods: {
    ...mapActions([
      'Send'
    ]),

    getSend () {
      if (!this.v$.$invalid) {
        this.formSettingTitle = this.formSettingFormTitle
        this.formSettingSubject = this.formSettingFormSubject

        this.Send({
          setting: {
            title: this.formSettingTitle,
            subject: this.formSettingSubject
          },

          fields: [
            {
              title: this.formFieldName,
              value: this.fieldName
            },
            {
              title: this.formFieldPhone,
              value: this.fieldPhone
            },
            {
              title: this.formFieldEmail,
              value: this.fieldEmail
            },
            {
              title: this.formFieldWidth,
              value: this.fieldWidth
            },
            {
              title: this.formFieldLength,
              value: this.fieldLength
            },
            {
              title: this.formFieldHeight,
              value: this.fieldHeight
            },
            {
              title: this.formFieldType,
              value: this.fieldType.join(', ')
            },
            {
              title: this.formFieldAddress,
              value: this.fieldAddress
            },
            {
              title: this.formFieldTime,
              value: this.fieldTime
            },
            {
              title: this.formPageName,
              value: this.formPagePagetitle + ' (' + this.formPageId + ')'
            }
          ],
          files: this.files
        })

        this.fieldName = this.fieldPhone = this.fieldEmail = this.fieldWidth = this.fieldLength = this.fieldHeight = this.fieldAddress = this.fieldTime = ''
        this.fieldType = []
        this.files = ''

        setTimeout(() => {
          window.location.href = this.formPageRedirect
        }, 1500)
      }
    }
  },

  validations () {
    return {
      fieldName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(50)
      },

      fieldPhone: {
        required,
        minLength: minLength(18),
        maxLength: maxLength(18)
      },

      consent: {
        required
      }
    }
  }
}
</script>
